import React from "react"
import classNames from "classnames"
import { useStaticQuery, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import SEO from "../components/seo"
import Layout from "../components/Layout/layout"
// import Projects from "../components/Sections/Projects/Projects"
import styles from "./about.module.scss"

export default () => {
  const { contentfulPerson } = useStaticQuery(graphql`
    query {
      contentfulPerson {
        name
        title
        location
        bio {
          bio
        }
        resume {
          file {
            url
          }
        }
      }
    }
  `)
  return (
    <Layout mainClassName={styles.about}>
      <SEO
        title="About"
        description="Selection of projects that I've worked on over the years."
      />
      <section className="animated fadeInDown">
        <div className={classNames(styles.contentWrapper, "row")}>
          <div className="col-md-4">
            <div className="title animated fadeInDown">
              <h2>{contentfulPerson.name}</h2>
              <h3 className={styles.subtitle}>{contentfulPerson.title}</h3>
              <p className={classNames(styles.location, "location icon ion-ios-pin")}>{contentfulPerson.location}</p>
              <a
//                 href={contentfulPerson.resume.file.url}
href="https://read.cv/songa"
                className={classNames(styles.resume, "icon ion-ios-arrow-forward")}
                target="_blank"
              >
                Resume
              </a>
            </div>
          </div>
          <div className="col-md-8 animated fadeInDown">
            <ReactMarkdown source={contentfulPerson.bio.bio} />
          </div>
        </div>
      </section>
    </Layout>
  )
}
